// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE

import * as ReasonReact from "reason-react/src/ReasonReact.js";
import * as ReactHelmet from "react-helmet";
import * as Js_null_undefined from "bs-platform/lib/es6/js_null_undefined.js";

function make(title, encodeSpecialCharacters, titleTemplate, defaultTitle, meta, children) {
  return ReasonReact.wrapJsForReason(ReactHelmet.Helmet, {
              title: Js_null_undefined.fromOption(title),
              encodeSpecialCharacters: Js_null_undefined.fromOption(encodeSpecialCharacters),
              titleTemplate: Js_null_undefined.fromOption(titleTemplate),
              defaultTitle: Js_null_undefined.fromOption(defaultTitle),
              meta: Js_null_undefined.fromOption(meta)
            }, children);
}

export {
  make ,
  
}
/* ReasonReact Not a pure module */
