// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE


const addScriptElement = function(src) {
  'use strict';

  const script = document.createElement("script");

  script.src = src;
  script.async = true;

  document.body.appendChild(script);
}

function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

function tryInitialize(exec) {
  return async function() {
    var initialized = false;
    var tries = 10; /* 10 * 300 = trying for 3s */
    do {
      try {
        exec.apply(null, arguments);
        initialized = true;
      }
      catch(exn) {
        /* console.log(exn); */
        tries = tries - 1;
        await sleep(300);
      }
    }
    while (! initialized && tries > 0)
  }
}
;

var initializeClass = (function(className) {
      return tryInitialize(function() {
        document.querySelectorAll('.' + className).forEach(function(element) {
          componentHandler.upgradeElement(element);
        })
      })
    });

var initializeButtons = initializeClass("mdl-js-button");

var initializeLayout = initializeClass("mdl-js-layout");

var initializeTabs = initializeClass("mdl-js-tabs");

var initializeTooltips = initializeClass("mdl-tooltip");

var Mdl = {
  initializeClass: initializeClass,
  initializeButtons: initializeButtons,
  initializeLayout: initializeLayout,
  initializeTabs: initializeTabs,
  initializeTooltips: initializeTooltips
};

var initializeVideos = (tryInitialize(function () {
      $('.video-link').magnificPopup({
        // disableOn: 700,
        type: 'iframe',
        midClick: true, // Allow opening popup on middle mouse click. Always set it to true if you don't provide alternative source in href.
        mainClass: 'mfp-zoom-in zoom-anim-dialog',
        removalDelay: 160,
        preloader: false
        // fixedContentPos: false
      });
    }));

var initializePopups = (tryInitialize(function () {
      $('.open-popup-link').magnificPopup({
        type:'inline',
        midClick: true, // Allow opening popup on middle mouse click. Always set it to true if you don't provide alternative source in href.
        removalDelay: 300,
        mainClass: 'mfp-zoom-in'
      });
    }));

var MagnificPopup = {
  initializeVideos: initializeVideos,
  initializePopups: initializePopups
};

export {
  Mdl ,
  MagnificPopup ,
  
}
/*  Not a pure module */
