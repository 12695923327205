// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE

import * as Curry from "bs-platform/lib/es6/curry.js";
import * as React from "react";
import * as Belt_Array from "bs-platform/lib/es6/belt_Array.js";
import * as ReactDOMRe from "reason-react/src/ReactDOMRe.js";
import * as Caml_option from "bs-platform/lib/es6/caml_option.js";
import * as ReasonReact from "reason-react/src/ReasonReact.js";
import * as Links$SystemaFr from "../utils/Links";
import * as Utils$SystemaFr from "../utils/Utils";
import * as Footer$SystemaFr from "./Footer";
import * as Gatsby$SystemaFr from "../utils/Gatsby";
import * as Header$SystemaFr from "./Header";
import * as Helmet$SystemaFr from "../utils/Helmet";
import * as Scripts$SystemaFr from "../utils/Scripts";

import styles from "./LayoutStatic.module.css"
;

var stylesheets = [
  "https://fonts.googleapis.com/icon?family=Material+Icons",
  "https://code.getmdl.io/1.3.0/material.blue_grey-orange.min.css",
  "https://fonts.googleapis.com/icon?family=Material+Icons",
  "https://fonts.googleapis.com/css?family=Roboto:400,500",
  "https://fonts.googleapis.com/css?family=Roboto+Slab:400,100,300,700",
  "https://fonts.googleapis.com/css?family=Montserrat:400,700"
];

var scripts = ["https://code.getmdl.io/1.3.0/material.min.js"];

var component = ReasonReact.statelessComponent("Layout");

function make($staropt$star, $staropt$star$1, data, children) {
  var pageTitle = $staropt$star !== undefined ? $staropt$star : "";
  var className = $staropt$star$1 !== undefined ? $staropt$star$1 : "";
  var metadata_title = data.title;
  var metadata_description = data.description;
  var metadata_keywords = data.keywords;
  var metadata_siteUrl = data.siteUrl;
  var metadata_copyright = data.copyright;
  return {
          debugName: component.debugName,
          reactClassInternal: component.reactClassInternal,
          handedOffState: component.handedOffState,
          willReceiveProps: component.willReceiveProps,
          didMount: (function (_self) {
              Curry._1(Scripts$SystemaFr.Mdl.initializeLayout, /* () */0);
              return Curry._1(Scripts$SystemaFr.Mdl.initializeButtons, /* () */0);
            }),
          didUpdate: component.didUpdate,
          willUnmount: component.willUnmount,
          willUpdate: component.willUpdate,
          shouldUpdate: component.shouldUpdate,
          render: (function (_self) {
              return React.createElement("div", {
                          className: styles.layout + " mdl-layout__container"
                        }, React.createElement("div", {
                              className: className + " heroin-layout mdl-layout mdl-js-layout"
                            }, ReasonReact.element(undefined, undefined, Helmet$SystemaFr.make(undefined, undefined, "%s — " + metadata_title, metadata_title, undefined, [
                                      React.createElement("html", {
                                            lang: "fr"
                                          }),
                                      React.createElement("meta", {
                                            content: metadata_description,
                                            name: "description"
                                          }),
                                      React.createElement("meta", {
                                            content: metadata_keywords,
                                            name: "keywords"
                                          }),
                                      React.createElement("meta", {
                                            content: metadata_title,
                                            property: "og:title"
                                          }),
                                      React.createElement("meta", {
                                            content: "website",
                                            property: "og:type"
                                          }),
                                      React.createElement("meta", {
                                            content: "",
                                            property: "og:image"
                                          }),
                                      React.createElement("meta", {
                                            content: metadata_siteUrl,
                                            property: "og:url"
                                          }),
                                      React.createElement("meta", {
                                            content: metadata_description,
                                            property: "og:description"
                                          }),
                                      React.createElement("meta", {
                                            content: "fr_FR",
                                            property: "og:locale"
                                          }),
                                      React.createElement("meta", {
                                            content: metadata_title,
                                            property: "og:site_name"
                                          }),
                                      React.createElement("meta", {
                                            content: "#263238",
                                            name: "theme-color"
                                          }),
                                      Belt_Array.map(stylesheets, (function (href) {
                                              return React.createElement("link", {
                                                          key: href,
                                                          defer: true,
                                                          href: href,
                                                          rel: "stylesheet"
                                                        });
                                            })),
                                      Belt_Array.map(scripts, (function (src) {
                                              return React.createElement("script", {
                                                          key: src,
                                                          defer: true,
                                                          src: src
                                                        });
                                            })),
                                      React.createElement("link", {
                                            defer: true,
                                            href: "https://unpkg.com/leaflet@1.3.4/dist/leaflet.css",
                                            rel: "stylesheet"
                                          }),
                                      React.createElement("link", {
                                            defer: true,
                                            href: "https://cdnjs.cloudflare.com/ajax/libs/magnific-popup.js/1.0.0/magnific-popup.min.css",
                                            rel: "stylesheet"
                                          }),
                                      React.createElement("script", {
                                            defer: true,
                                            src: "https://unpkg.com/leaflet@1.3.4/dist/leaflet.js"
                                          }),
                                      React.createElement("script", {
                                            defer: true,
                                            src: "https://unpkg.com/leaflet-providers@1.4.0/leaflet-providers.js"
                                          }),
                                      React.createElement("script", {
                                            defer: true,
                                            src: "https://cdnjs.cloudflare.com/ajax/libs/zepto/1.2.0/zepto.min.js"
                                          }),
                                      React.createElement("script", {
                                            defer: true,
                                            src: "https://cdnjs.cloudflare.com/ajax/libs/magnific-popup.js/1.1.0/jquery.magnific-popup.min.js"
                                          }),
                                      pageTitle === "" ? Utils$SystemaFr.$$null : React.createElement("title", undefined, Utils$SystemaFr.text(pageTitle))
                                    ])), ReasonReact.element(undefined, undefined, Header$SystemaFr.make(pageTitle, [])), React.createElement("div", {
                                  className: "mdl-layout__drawer"
                                }, React.createElement("a", {
                                      className: "mdl-layout-title",
                                      href: "/"
                                    }, Utils$SystemaFr.text("Systema Salle d’Armes")), React.createElement("nav", {
                                      className: "mdl-navigation"
                                    }, ReasonReact.element(undefined, undefined, Gatsby$SystemaFr.Link.make(Links$SystemaFr.classes, undefined, undefined, undefined, undefined, "mdl-navigation__link", undefined, undefined, undefined, undefined, [Utils$SystemaFr.text("Cours")])), ReasonReact.element(undefined, undefined, Gatsby$SystemaFr.Link.make(Links$SystemaFr.news, undefined, undefined, undefined, undefined, "mdl-navigation__link", undefined, undefined, undefined, undefined, [Utils$SystemaFr.text("Actualités")])), ReasonReact.element(undefined, undefined, Gatsby$SystemaFr.Link.make(Links$SystemaFr.library, undefined, undefined, undefined, undefined, "mdl-navigation__link", undefined, undefined, undefined, undefined, [Utils$SystemaFr.text("Bibliothèque")])), ReasonReact.element(undefined, undefined, Gatsby$SystemaFr.Link.make(Links$SystemaFr.join, undefined, undefined, undefined, undefined, "mdl-navigation__link", undefined, undefined, undefined, undefined, [Utils$SystemaFr.text("Inscription")])))), React.createElement("main", {
                                  className: "mdl-layout__content"
                                }, ReactDOMRe.createElementVariadic("div", undefined, children), ReasonReact.element(undefined, undefined, Footer$SystemaFr.make(metadata_copyright, [])))));
            }),
          initialState: component.initialState,
          retainedProps: component.retainedProps,
          reducer: component.reducer,
          jsElementWrapped: component.jsElementWrapped
        };
}

var $$default = ReasonReact.wrapReasonForJs(component, (function (jsProps) {
        return make(Caml_option.nullable_to_opt(jsProps.pageTitle), Caml_option.nullable_to_opt(jsProps.className), jsProps.data, jsProps.children);
      }));

export {
  stylesheets ,
  scripts ,
  component ,
  make ,
  $$default ,
  $$default as default,
  
}
/*  Not a pure module */
