// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE

import * as ReasonReact from "reason-react/src/ReasonReact.js";
import * as LayoutData from "./LayoutData";
import * as Js_null_undefined from "bs-platform/lib/es6/js_null_undefined.js";

var layoutData = LayoutData.default;

function make(pageTitle, className, children) {
  return ReasonReact.wrapJsForReason(layoutData, {
              pageTitle: Js_null_undefined.fromOption(pageTitle),
              className: Js_null_undefined.fromOption(className)
            }, children);
}

export {
  layoutData ,
  make ,
  
}
/* layoutData Not a pure module */
