// import './index.css'

import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import LayoutStatic from './LayoutStatic.re'

const LayoutData = ({ pageTitle, className, children }) => (
  <StaticQuery
    query={graphql`
      {
        site {
          siteMetadata {
            title
            description
            keywords
            siteUrl
            copyright
          }
        }
      }
    `}
    render={data => (
      <LayoutStatic
          pageTitle={pageTitle}
          className={className}
          data={data.site.siteMetadata}>
        {children}
      </LayoutStatic>
    )}
  />
)

export default LayoutData
