// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE


var home = "/";

var classes = "/cours";

var news = "/actualites";

var library = "/bibliotheque";

var join = "/inscription";

export {
  home ,
  classes ,
  news ,
  library ,
  join ,
  
}
/* No side effect */
