// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE

import * as React from "react";
import * as ReasonReact from "reason-react/src/ReasonReact.js";
import * as Gatsby$SystemaFr from "../utils/Gatsby";

var component = ReasonReact.statelessComponent("ListLink");

function make(className, to_, children) {
  return {
          debugName: component.debugName,
          reactClassInternal: component.reactClassInternal,
          handedOffState: component.handedOffState,
          willReceiveProps: component.willReceiveProps,
          didMount: component.didMount,
          didUpdate: component.didUpdate,
          willUnmount: component.willUnmount,
          willUpdate: component.willUpdate,
          shouldUpdate: component.shouldUpdate,
          render: (function (_self) {
              return React.createElement("li", undefined, ReasonReact.element(undefined, undefined, Gatsby$SystemaFr.Link.make(to_, undefined, undefined, undefined, undefined, className, undefined, undefined, undefined, undefined, children)));
            }),
          initialState: component.initialState,
          retainedProps: component.retainedProps,
          reducer: component.reducer,
          jsElementWrapped: component.jsElementWrapped
        };
}

var ListLink = {
  component: component,
  make: make
};

export {
  ListLink ,
  
}
/* component Not a pure module */
