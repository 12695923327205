// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE

import * as Gatsby from "gatsby";
import * as ReasonReact from "reason-react/src/ReasonReact.js";
import * as Js_null_undefined from "bs-platform/lib/es6/js_null_undefined.js";

var filterProps = (function(props) {
      var newProps = {};
      for(var key in props) {
        if(props[key] !== undefined) {
          newProps[key] = props[key];
        }
      }
      return newProps;
    });

function make(to_, activeStyle, style, innerRef, onClick, className, activeClassName, replace, exact, strict, children) {
  var jsProps = {
    to: to_,
    activeStyle: Js_null_undefined.fromOption(activeStyle),
    style: Js_null_undefined.fromOption(style),
    innerRef: Js_null_undefined.fromOption(innerRef),
    onClick: Js_null_undefined.fromOption(onClick),
    className: Js_null_undefined.fromOption(className),
    activeClassName: Js_null_undefined.fromOption(activeClassName),
    replace: Js_null_undefined.fromOption(replace),
    exact: Js_null_undefined.fromOption(exact),
    strict: Js_null_undefined.fromOption(strict)
  };
  return ReasonReact.wrapJsForReason(Gatsby.Link, filterProps(jsProps), children);
}

function navigate(prim) {
  Gatsby.navigate(prim);
  return /* () */0;
}

function withPrefix(prim) {
  return Gatsby.withPrefix(prim);
}

var Link = {
  make: make,
  navigate: navigate,
  withPrefix: withPrefix
};

export {
  Link ,
  
}
/* gatsby Not a pure module */
