// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE

import * as Bytes from "bs-platform/lib/es6/bytes.js";
import * as Curry from "bs-platform/lib/es6/curry.js";
import * as React from "react";
import * as $$String from "bs-platform/lib/es6/string.js";
import * as Caml_bytes from "bs-platform/lib/es6/caml_bytes.js";
import * as Belt_Option from "bs-platform/lib/es6/belt_Option.js";
import * as ReasonReact from "reason-react/src/ReasonReact.js";
import * as Utils$SystemaFr from "../utils/Utils";

function rot13(s) {
  return s.replace(/[a-zA-Z]/g, function(c){
    return String.fromCharCode((c<='Z'?90:122)>=(c=c.charCodeAt(0)+13)?c:c-26);
  });
}

function openLink(href) {
  window.location.href = href;
}
;

var linkStyle = {
  direction: "rtl",
  unicodeBidi: "bidi-override"
};

var splitStyle = {
  display: "none"
};

function reverseString(str) {
  var stringLength = str.length;
  var bs = Bytes.of_string(str);
  var loop = function (_index) {
    while(true) {
      var index = _index;
      var startChar = Caml_bytes.get(bs, index);
      var endCharIndex = (stringLength - index | 0) - 1 | 0;
      bs[index] = Caml_bytes.get(bs, endCharIndex);
      bs[endCharIndex] = startChar;
      if ((index + 1 | 0) < endCharIndex) {
        _index = index + 1 | 0;
        continue ;
      } else {
        return 0;
      }
    };
  };
  loop(0);
  return Bytes.to_string(bs);
}

function splitString(index, str) {
  var stringLength = str.length;
  var splitIndex = index !== undefined ? index : (
      $$String.contains(str, /* "@" */64) ? $$String.index(str, /* "@" */64) + 1 | 0 : stringLength / 2 | 0
    );
  return /* tuple */[
          $$String.sub(str, 0, splitIndex),
          $$String.sub(str, splitIndex, stringLength - splitIndex | 0)
        ];
}

var component = ReasonReact.statelessComponent("ObfuscatedLink");

function make($staropt$star, style, splitAt, href, label, children) {
  var className = $staropt$star !== undefined ? $staropt$star : "";
  var match = splitString(splitAt, label);
  var sndPart = match[1];
  var fstPart = match[0];
  var click = function ($$event, self) {
    $$event.preventDefault();
    openLink(href);
    return /* () */0;
  };
  var linkStyle$1 = Belt_Option.mapWithDefault(style, linkStyle, (function (style) {
          return Object.assign(({}), linkStyle, style);
        }));
  return {
          debugName: component.debugName,
          reactClassInternal: component.reactClassInternal,
          handedOffState: component.handedOffState,
          willReceiveProps: component.willReceiveProps,
          didMount: component.didMount,
          didUpdate: component.didUpdate,
          willUnmount: component.willUnmount,
          willUpdate: component.willUpdate,
          shouldUpdate: component.shouldUpdate,
          render: (function (self) {
              return React.createElement("a", {
                          className: className,
                          style: linkStyle$1,
                          href: "",
                          onClick: Curry._1(self.handle, click)
                        }, Utils$SystemaFr.text(reverseString(sndPart)), React.createElement("span", {
                              style: splitStyle
                            }, Utils$SystemaFr.text("removeme")), Utils$SystemaFr.text(reverseString(fstPart)));
            }),
          initialState: component.initialState,
          retainedProps: component.retainedProps,
          reducer: component.reducer,
          jsElementWrapped: component.jsElementWrapped
        };
}

export {
  linkStyle ,
  splitStyle ,
  reverseString ,
  splitString ,
  component ,
  make ,
  
}
/*  Not a pure module */
