// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE

import * as React from "react";
import * as ReasonReact from "reason-react/src/ReasonReact.js";
import * as Links$SystemaFr from "../utils/Links";
import * as Utils$SystemaFr from "../utils/Utils";
import * as Gatsby$SystemaFr from "../utils/Gatsby";

import owlIcon from "../assets/images/owl-icon-32px.png"
import facebookIcon from "../assets/images/f_logo_RGB-White_58.png"
;

var component = ReasonReact.statelessComponent("Header");

function make(title, _children) {
  return {
          debugName: component.debugName,
          reactClassInternal: component.reactClassInternal,
          handedOffState: component.handedOffState,
          willReceiveProps: component.willReceiveProps,
          didMount: component.didMount,
          didUpdate: component.didUpdate,
          willUnmount: component.willUnmount,
          willUpdate: component.willUpdate,
          shouldUpdate: component.shouldUpdate,
          render: (function (_self) {
              var linkClassName = "mdl-navigation__link";
              var activeLinkClassName = "is-active";
              return React.createElement("header", {
                          className: "heroin-layout__intro"
                        }, React.createElement("div", {
                              className: "mdl-layout__header"
                            }, React.createElement("div", {
                                  className: "mdl-layout-icon"
                                }), React.createElement("div", {
                                  className: "mdl-layout__header-row"
                                }, React.createElement("span", {
                                      className: "heroin-logo mdl-layout-title mdl-layout--large-screen-only"
                                    }, React.createElement("img", {
                                          alt: "Logo",
                                          src: owlIcon
                                        })), React.createElement("span", {
                                      className: "heroin-title mdl-layout-title"
                                    }, ReasonReact.element(undefined, undefined, Gatsby$SystemaFr.Link.make(Links$SystemaFr.home, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, [
                                              Utils$SystemaFr.text(" Systema /  "),
                                              React.createElement("span", {
                                                    className: "light"
                                                  }, Utils$SystemaFr.text("Salle d’Armes"))
                                            ]))), React.createElement("div", {
                                      className: "mdl-layout-spacer"
                                    }), React.createElement("nav", {
                                      className: "mdl-navigation mdl-layout--large-screen-only"
                                    }, ReasonReact.element(undefined, undefined, Gatsby$SystemaFr.Link.make(Links$SystemaFr.classes, undefined, undefined, undefined, undefined, linkClassName, activeLinkClassName, undefined, undefined, undefined, [Utils$SystemaFr.text("Cours")])), ReasonReact.element(undefined, undefined, Gatsby$SystemaFr.Link.make(Links$SystemaFr.news, undefined, undefined, undefined, undefined, linkClassName, activeLinkClassName, undefined, undefined, undefined, [Utils$SystemaFr.text("Actualités")])), ReasonReact.element(undefined, undefined, Gatsby$SystemaFr.Link.make(Links$SystemaFr.library, undefined, undefined, undefined, undefined, linkClassName, activeLinkClassName, undefined, undefined, undefined, [Utils$SystemaFr.text("Bibliothèque")])), ReasonReact.element(undefined, undefined, Gatsby$SystemaFr.Link.make(Links$SystemaFr.join, undefined, undefined, undefined, undefined, "mdl-navigation__link highlight", activeLinkClassName, undefined, undefined, undefined, [Utils$SystemaFr.text("Inscription")])), React.createElement("a", {
                                          className: linkClassName,
                                          href: "https://www.facebook.com/systemasalledarmes/",
                                          target: "_blank"
                                        }, React.createElement("img", {
                                              className: "navigation__icon",
                                              alt: "Facebook icon",
                                              src: facebookIcon
                                            }))))));
            }),
          initialState: component.initialState,
          retainedProps: component.retainedProps,
          reducer: component.reducer,
          jsElementWrapped: component.jsElementWrapped
        };
}

export {
  component ,
  make ,
  
}
/*  Not a pure module */
